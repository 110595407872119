import "echarts/lib/chart/line"; 
import "echarts/lib/chart/bar";
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/graphic';
import 'zrender/lib/svg/svg';
import 'echarts/lib/component/markLine';
import 'echarts/lib/langEN'
import 'echarts/lib/langEN.js'
import 'echarts/lib/component/visualMap';



